import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './env/app/services/guards/auth.guard';

const routes: Routes = [

  {
    path:'login',
    loadChildren: () => import('./env/app/login/login.module').then(m => m.LoginModule)
    
  },
  {
    path:'login_interno',
    loadChildren: () => import('./env/app/login-interno/login-interno.module').then(m => m.LoginInternoModule)
    
  },
  {
    path:'login/:nex_url',
    loadChildren: () => import('./env/app/login/login.module').then(m => m.LoginModule)
    
  },
  {
    path:'',
    loadChildren: () => import('./env/app/home/home.module').then(m => m.HomeModule),
    canLoad:[
      AuthGuard
    ],
    canActivate:[
      AuthGuard
    ]
    
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{
    //useHash:true
    useHash:false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
